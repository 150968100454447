import React from 'react';
import { ITicket } from '../../../../types';
import HeaderItem from './HeaderItem';
import Ticket from './Ticket';
import './tickets.scss';

interface Props {
    tickets: ITicket[];
}

const Tickets: React.FC<Props> = ({ tickets }) => {
    return (
        <div className="tickets">
            <div className="tickets__header flex">
                <HeaderItem label="TICKET ID" className="f2" />
                <HeaderItem label="STATUS" className="f1" />
                <HeaderItem label="TYPE" className="f1" />
                <HeaderItem label="CATEGORY" className="f2" />
                <HeaderItem label="DATE CREATED" className="f2" />
            </div>

            {tickets.map(ticket => (
                <Ticket key={ticket.id} ticket={ticket} />
            ))}
        </div>
    );
};

export default Tickets;
