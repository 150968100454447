import React, { useState } from 'react';
import { ITicket } from '../../../../types';
import { Button } from '@missionlabs/smartagent-app-components';
import copyIcon from '../../icons/copy.svg';
import tickIcon from'../../icons/tick-fill.svg';

interface Props {
    ticket: ITicket;
}

const Ticket: React.FC<Props> = ({ ticket }) => {
    const [copyTicketNumber, setCopyTicketNumber] = useState(false);

    const copyToClipboard = (copiedText: string) => {
        navigator.clipboard.writeText(copiedText);
        setCopyTicketNumber(true);
        setTimeout(() => {
            setCopyTicketNumber(false);
        }, 3000);
    };

    return (
        <div className="tickets__item flex">
            <div className="f2 tickets__item__with-copy-btn flex">
                <p>{ticket.id}</p>
                <Button
                    onClick={() => copyToClipboard(ticket.id)}
                    className="tickets__item__copy-button"
                >
                    {copyTicketNumber ? (
                        <img src={tickIcon} width="16px" height="16px" alt="Copied to clipboard" />
                    ) : (
                        <img src={copyIcon} alt="copy ticket id" />
                    )}
                </Button>
               
            </div>
            <p className="f1">{ticket.status}</p>
            <p className="f1">{ticket.type}</p>
            <p className="f2">{ticket.category}</p>
            <p className="f2">{ticket.createdDate}</p>
        </div>
    );
};

export default Ticket;
