import React from 'react'
import Main from './components/Main';
import { PluginHarness } from '@missionlabs/smartagent-app-components';

const devContact = {
    ID: '010701ac-e99e-4f78-8d58-3027a68b5b0e',
};

const App : React.FC<{}> = () => {

    const isEmbedded = window.parent !== window;

    return (
        <>
            {isEmbedded
                ? <Main />
                : <PluginHarness contact={devContact}>
                      <Main />
                  </PluginHarness>}
        </>
    )
}

export default App
